.footer {
  background: #888;
  padding: 3rem 5vw 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  &__links {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 2rem;
    &__contact {
      display: flex;
      flex-direction: column;
      &__item {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
        &__title {
          margin-bottom: 0.5rem;
        }
        &__link {
          cursor: pointer;
          font-size: 0.9rem;
          display: flex;
          flex-direction: column;

          > button {
            margin-top: 0.5rem;
            width: fit-content;
          }
          // &:hover {
          //   text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.3);
          // }
        }
      }
    }
    &__list {
      display: flex;
      &__sublist {
        display: flex;
        flex-direction: column;
        margin: 0 1.5rem;
        &__title {
          margin-bottom: 0.5rem;
        }
        &__link {
          cursor: pointer;
          font-size: 0.9rem;
          margin-bottom: 0.5rem;
          position: relative;
          width: fit-content;
          &:hover::after {
            content: '';
            position: absolute;
            bottom: -3px;
            left: 0;
            width: 100%;
            height: 1.5px;
            background: #000;
          }
        }
      }
    }
  }
  &__copyright {
    font-size: 0.8rem;
    font-weight: 300;
    letter-spacing: 1px;
    text-align: center;
    align-self: center;
  }
}
@media screen and (max-width: 858px) {
  .footer__links {
    flex-direction: column;
    &__contact {
      flex-direction: row;
      margin-bottom: 2rem;
      &__item {
        margin-right: 2rem;
      }
    }
    &__list__sublist {
      margin: 0 2rem 0 0;
    }
  }
}
@media screen and (max-width: 400px) {
  .footer__links {
    flex-direction: column;
    &__contact {
      flex-direction: column;
      margin-bottom: 2rem;
      &__item {
        margin-right: 0;
      }
    }
    &__list {
      flex-direction: column;
      &__sublist {
        margin: 0 0 1rem 0;
      }
    }
  }
}
