.header {
  z-index: -1;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  &__slide {
    width: 100%;
    height: 100vh;
    overflow: hidden;

    &__image {
      width: 100%;
      height: 100%;
      position: fixed;
      object-fit: cover;
      object-position: center;
    }
  }
}
.about {
  padding: 6rem 0 3rem;
  &__card {
    padding: 1.5rem;
    border-radius: 1rem;
    background: linear-gradient(
      to bottom right,
      rgba(150, 150, 150, 0.8),
      rgba(150, 150, 150, 0.4)
    );
    &__title {
      font-size: 2rem;
      margin-bottom: 0.5rem;
    }
    &__history {
      font-size: 1.1rem;
      line-height: 1.3;
      &__link {
        color: #056436;
        font-weight: 500;
      }
    }
  }
}

.businesses {
  padding: 6rem 0 0;
  &__title {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    text-align: center;
  }
  &__grid {
    display: grid;
    grid-template-areas:
      'rj surgeon'
      'rj surgeon'
      'rj precise'
      'rj ceramic'
      'prispek spektra'
      'prisma liquor'
      'prisma liquor';
    // align-items: center;
    justify-content: stretch;
    grid-gap: 1.5rem;
    &__item {
      background: white;
      &:hover {
        filter: brightness(80%);
      }
      &__image {
        width: 100%;
      }
      &.prispek {
        grid-area: prispek;
      }
      &.spektra {
        grid-area: spektra;
      }
      &.prisma {
        grid-area: prisma;
      }
      &.ceramic {
        filter: brightness(96%);
        grid-area: ceramic;
      }
      &.precise {
        filter: brightness(96%);
        grid-area: precise;
      }
      &.liquor {
        grid-area: liquor;
      }
      &.surgeon {
        grid-area: surgeon;
      }
      &.rj {
        grid-area: rj;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .businesses__grid {
    grid-template-areas:
      'surgeon'
      'rj'
      'precise'
      'ceramic'
      'prispek'
      'spektra'
      'liquor'
      'prisma';
  }
}
.centre {
  display: flex;
  justify-content: space-around;
  align-items: center;
  &__buttons {
    margin-top: 0.5rem !important;
    width: fit-content !important;
  }
  &--container {
    padding: 6rem 0;
    display: flex;
    flex-direction: column;
    &__title {
      font-size: 2.5rem;
      margin-bottom: 2.5rem;
      text-align: center;
    }
  }
  &__image {
    width: 25rem;
    border-radius: 1rem;
  }
  &__content {
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    border-radius: 1rem;
    background: linear-gradient(
      to bottom right,
      rgba(150, 150, 150, 0.8),
      rgba(150, 150, 150, 0.4)
    );
    &__item {
      display: flex;
      flex-direction: column;
      margin-bottom: 2rem;
      &__title {
        margin-bottom: 0.5rem;
        font-size: 1.4rem;
      }
      &__link {
        font-size: 1.1rem;
        display: flex;
        flex-direction: column;
        margin-bottom: 0.3rem;
      }
    }
  }
}
@media screen and (max-width: 1000px) {
  .centre__image {
    width: 20rem;
  }
}
@media screen and (max-width: 800px) {
  .centre__image {
    width: 18rem;
  }
}
@media screen and (max-width: 800px) {
  .centre {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__image {
      margin-bottom: 2rem;
    }
  }
}
