@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  scroll-behavior: smooth;
}

body {
  cursor: context-menu;
  scroll-behavior: smooth;
  background: #eee;
}

a {
  text-decoration: none;
  color: inherit;
}
ul {
  list-style: none;
}

::-webkit-scrollbar {
  width: 0.8rem;
}

::-webkit-scrollbar-track {
  background: #eee;
}

::-webkit-scrollbar-thumb {
  background: #f9eb8c;
}

::-webkit-scrollbar-thumb:hover {
  background: #f9ea8c8f;
}

::selection {
  background: #f9eb8c;
  color: rgb(42, 42, 42);
}
